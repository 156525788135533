// Small devices (landscape phones, 576px and up)

.navbar-toggler {
    margin-right: 20px;
}

.navbar {
    border-right: 1px solid rgb(241, 241, 241);
    box-shadow: 6px 2px 15px -2px rgba(0, 0, 0, 0.219);
    padding-left: 5px;
    font-size: 1.2rem;
    font-weight: 400;
    z-index: 10;
    opacity: 1;
    background-color: white !important;

    .logo {
        color: rgb(75, 75, 75) !important;
        opacity: 1;
        text-decoration: none;
        margin-left: 20px;

        &:hover {
            text-decoration: none;
            color: rgb(17, 17, 17);
            opacity: 1;
        }
    }

    .nav-item {
        padding-left: 8px !important;
        margin-left: 15px;

        &:hover {
            color: #000000;
        }
    }
}

.active {
    color: #000000;
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .navbar {
        border-right: 1px solid rgb(241, 241, 241);
        box-shadow: 6px 2px 15px -2px rgba(0, 0, 0, 0.219);
        padding-left: 30px;
        font-size: 1.2rem;
        font-weight: 400;
        z-index: 10;
        opacity: 1;
        background-color: white !important;

        .logo {
            color: rgb(75, 75, 75) !important;
            opacity: 1;

            &:hover {
                text-decoration: none;
                color: rgb(17, 17, 17);
                opacity: 1;
            }
        }

        .nav-item {
            padding-left: 8px !important;

            &:hover {
                color: #000000;
            }
        }
    }
}

@media (min-width: 992px) {
    .navbar {
        border-right: 1px solid rgb(241, 241, 241);
        box-shadow: 6px 2px 15px -2px rgba(0, 0, 0, 0.219);
        padding-left: 30px;
        font-size: 1.2rem;
        font-weight: 400;
        z-index: 10;
        opacity: 1;
        background-color: white !important;

        .logo {
            color: rgb(75, 75, 75) !important;
            opacity: 0.7;

            &:hover {
                text-decoration: none;
                color: rgb(17, 17, 17);
                opacity: 1;
            }
        }

        .nav-item {
            padding-left: 8px !important;

            &:last-child {
                margin-right: 20px;
            }

            &:hover {
                color: #000000;
            }
        }
    }
}

@media (min-width: 1200px) {
    .navbar {
        border-right: 1px solid rgb(241, 241, 241);
        box-shadow: 6px 2px 15px -2px rgba(0, 0, 0, 0.219);
        padding-top: 5px;
        padding-left: 30px;
        font-size: 1.4rem;
        font-weight: 400;
        z-index: 10;
        opacity: 1;
        background-color: white !important;

        .logo {
            color: rgb(75, 75, 75) !important;
            opacity: 0.7;

            &:hover {
                text-decoration: none;
                color: rgb(17, 17, 17);
                opacity: 1;
            }
        }

        .nav-item {
            padding-left: 8px !important;

            &:hover {
                color: #000000;
            }
        }
    }
}
