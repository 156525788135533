.gallery {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-around;
    flex-shrink: 0;

    .card {
        border: 1px solid rgb(129, 129, 129);
        width: 80%;
        margin: 30px 15px 30px 15px;
        align-items: center;
        justify-content: center;
        // display: inline-block;

        &-body {
            &--text {
                ul {
                    list-style-type: none;
                    padding: 9px 15px;
                    text-align: left !important ;

                    li {
                        padding: 6px 0;
                    }
                }

                &_description {
                    margin-left: 10px;
                }
            }
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .gallery {
        margin-top: 20px;

        .card {
            border: 1px solid rgb(129, 129, 129);
            width: 30%;
            margin: 15px;

            &-body {
                &--text {
                    ul {
                        list-style-type: none;
                        padding: 9px 15px;
                        text-align: left !important ;

                        li {
                            padding: 6px 0;
                        }
                    }

                    &_description {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .gallery {
        margin-top: 20px;

        .card {
            border: 1px solid rgb(129, 129, 129);
            width: 22%;
            margin: 15px;

            &-body {
                &--text {
                    ul {
                        list-style-type: none;
                        padding: 9px 15px;
                        text-align: left !important ;

                        li {
                            padding: 6px 0;
                        }
                    }

                    &_description {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .gallery {
        margin-top: 20px;

        .card {
            border: 1px solid rgb(129, 129, 129);
            width: 24%;
            margin: 5px;

            &-body {
                &--text {
                    ul {
                        list-style-type: none;
                        padding: 9px 15px;
                        text-align: left !important ;

                        li {
                            padding: 6px 0;
                        }
                    }

                    &_description {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
