.social-row {
    padding: 5px;
    margin-top: 20px;

    text-align: center;
    img {
        width: 15%;
        display: inline-block;
        opacity: 0.6;
    }

    a,
    span {
        color: rgb(43, 43, 43);
        padding: 15px 10px;
        opacity: 0.75;

        &:first-child {
            padding: 15px 10px 15px 0;
        }

        &:hover {
            color: rgb(43, 43, 43);
            border-radius: 5px;
            opacity: 1;
            cursor: pointer;
        }
    }
}

@media (min-width: 992px) {
    .social-row {
        display: none;
    }
}
