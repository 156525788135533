.header {
    text-align: center;
    margin-top: 3em;
    margin-bottom: 2em;

    h1 {
        font-weight: 300;
        font-size: 2.8em;
    }

    h2 {
        font-weight: 200;
    }

    hr {
        margin-top: 2em;
    }
}
