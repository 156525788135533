@import url('https://fonts.googleapis.com/css?family=Bubbler+One&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400&display=swap');

* {
    padding: 0;
    margin: 0;
}

body {
    font-family: 'Lato', sans-serif;
}

.sidebar {
    grid-area: sidebar;
}

.navigation {
    grid-area: navbar;
}

.main {
    grid-area: main;
}

.footer {
    grid-area: footer;
}

.App {
    display: grid;
    grid-template-rows: 5rem auto auto;
    grid-template-columns: auto;
    grid-template-areas:
        'navbar'
        'main'
        'footer';
}

.main {
    background-color: #ffffff;
    margin-top: -30px;
}

.social-row-modal-body {
    padding: 20px;

    p {
        margin-top: 20px;
    }

    a {
        text-decoration: none;
        &:link,
        &:active,
        &:visited {
            color: rgb(46, 132, 251);
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

.sidebar-modal-body {
    padding: 20px;

    p {
        margin-top: 20px;
    }

    a {
        text-decoration: none;
        &:link,
        &:active,
        &:visited {
            color: rgb(46, 132, 251);
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .App {
        display: grid;
        grid-template-rows: 5rem auto auto;
        grid-template-columns: auto;
        grid-template-areas:
            'navbar'
            'main'
            'footer';
    }

    .main {
        margin-top: -30px;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .App {
        display: grid;
        grid-template-rows: 5rem auto auto;
        grid-template-columns: auto;
        grid-template-areas:
            'navbar'
            'main'
            'footer';
    }

    .main {
        margin-top: -20px;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .App {
        display: grid;
        grid-template-rows: 5rem auto auto;
        grid-template-columns: auto;
        grid-template-areas:
            'navbar'
            'main'
            'footer';
    }

    .main {
        margin-top: -10px;
    }
}
