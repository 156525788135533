.info-panel {
    margin: auto;
    margin-top: 1.5em;
    padding: 1em 0;
    align-content: center;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 90%;
}

.left {
    text-align: center;
    width: 200px;
    margin: auto;
}

.right {
    margin-top: 0;

    h3 {
        text-align: center;
        font-weight: 300;
    }

    p {
        text-align: left;
        padding: 0 1em;
        font-weight: 300;
    }
}

p {
    font-size: 1em;
    line-height: 40px;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .left {
        text-align: center;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .info-panel {
        margin: auto;
        margin-top: 3em;
        padding: 1em 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 650px;
    }

    .left {
        text-align: center;
        width: 190px;
    }

    .right {
        width: 450px;
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .info-panel {
        margin: auto;
        margin-top: 3em;
        padding: 1em 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 760px;
    }

    .left {
        width: 250px;
        margin-left: 2em;
        display: flex;
        align-self: center;
    }

    .right {
        margin-right: 1em;
        margin-top: 0;
        width: 500px;
        padding-left: 1em;
        text-align: justify;
    }

    p {
        font-size: 1.1em;

        &:first-of-type {
            margin-top: 3em;
        }
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .info-panel {
        margin-top: 3em;
        padding: 1em 0;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: white;
    }

    .left {
        width: 250px;
        margin-left: 1em;
    }

    .right {
        margin-right: 1em;
        margin-top: 0;
        width: 600px;
        text-align: justify;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
    }

    p {
        font-size: 1.1em;

        &:first-of-type {
            margin-top: 3em;
        }
    }
}
