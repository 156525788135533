.modaltoggle {
    display: none;
}

.rmodal {
    width: 100vw;
}

.thumbnail {
    pointer-events: none;
}

.card {
    border-width: 0 !important;
    border-color: transparent !important;

    // &:hover {
    //     cursor: pointer;
    //     opacity: 0.9;
    // }
}

.rmodal {
    width: 100vw !important;
    height: 100vh !important;
    display: flex;
    align-content: center;
    align-items: center;
    align-self: center;
    justify-content: center;

    &-header {
        display: none !important;
    }

    &-content {
        width: 90vw !important;
        margin-bottom: 100px;
        margin-right: 15px;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    .rmodal {
        margin-top: 0 !important;
        width: 100vw !important;
        height: 100vh !important;

        &-content {
            margin-top: 0 !important;
            width: 600px !important;
            max-height: 600px !important;
            margin: auto !important;
            display: flex;
            align-items: center;
        }

        &-header {
            width: 900px !important;
        }

        &-body {
            // display: flex;
            max-width: 550px !important;
            height: 550px !important;
        }
    }
}

.modal-dialog {
    max-width: 100vw !important;
}
